/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable no-irregular-whitespace */
import { getMemberData, getRouterPushPropertiesData, setRouterPushPropertiesData } from './localStorageUtil'
import { setCompanionData, getCompanionData, setVehicleData, getVehicleData, setMemberData } from './localStorageUtil'
import {
    SEAT_TYPES,
    SEAT_TYPE_P_TW,
    SEAT_TYPE_P_TR,
    SEAT_TYPE_P_BA,
    SEAT_TYPE_F_TW,
    SEAT_TYPE_F_FO,
    SEAT_TYPE_FWP_TW,
    SEAT_TYPE_FWP_FO,
    SEAT_TYPE_S,
    SEAT_TYPE_DS,
    SEAT_TYPE_D,
    SEAT_TYPE_T,
    GRADES,
    GRADE_P,
    GRADE_F,
    GRADE_S,
    GRADE_DS,
    GRADE_D,
    GRADE_T,
    PASSENGER_TYPES,
    PASSENGER_TYPE_ADULT,
    PASSENGER_TYPE_CHILD,
    PASSENGER_TYPE_INFANT,
    PASSENGER_TYPE_BABY,
    convertText,
    AUTHORI_PAYMENT_METHOD_CREDIT,
    AUTHORI_PAYMENT_METHODS,
    AUTHORI_PAYMENT_METHOD_PAY_EASY,
    AUTHORI_PAYMENT_METHOD_CONVENIENCE_STORE,
} from '@/lib/constants'
import {
    Contract,
    FarePassenger,
    FareVehicle,
    FareReserved,
    CancelPolicyDetail,
    CompanionDetail,
    VehicleDetail,
    AddressDetail,
    RoomInformation,
    MemberDetail,
    ShipSchedule,
    ContractPassengerFare,
    ContractCarFare,
    ContractTwoWheelFare,
    ContractReserved,
    StockDetail,
    PassengerDetail,
    CarDetail,
    RouterPushProperties,
} from '@/lib/types'
import apiClient from '@/plugins/axioswrap'
import { AxiosResponse, AxiosError } from 'axios'
const YubinBango = require('yubinbango-core2')
import { useStore } from '@/store'

/**
 * 定数定義されていない定数
 */

// APIリポジトリ
export const API_USERS = '/users'
export const API_ORDER = '/order'

// 航路
export const SEA_ROUTE_CODE_MIYAZAKI = '01'
export const SEA_ROUTE_CODE_KOBE = '02'

// 車両区分
export const CAR_TYPE_PASSENGER = '04' as string
export const CAR_TYPE_TWO_WHEEL_LARGE = '05' as string
export const CAR_TYPE_TWO_WHEEL_MEDIUM = '06' as string
export const CAR_TYPE_TWO_WHEEL_SMALL = '07' as string
export const CAR_TYPE_BICYCLE = '08' as string

// 船
export const SHIP_CODE_TAKACHIHO = '11'
export const SHIP_CODE_ROKKO = '12'

export const DEFAULT_TWO_WHEEL_LENGTH = 0 as number
export const DEFAULT_TWO_WHEEL_WIDTH = 0 as number
export const DEFAULT_CAR_WIDTH = 2.5 as number

// 年齢制限
export const RANGE_OF_PASSENGER_AGES = [
    [12, null], // 大人
    [1, 12], // 小人
    [1, 6], // 幼児
    [0, 0], // 乳児
]

// 部屋詳細情報
export const ROOM_INFORMATIONS = [
    {
        seat_type: SEAT_TYPE_P_TW,
        seat_type_name: 'プレミアムツイン',
        grade: GRADE_P,
        room_type: '洋室ツイン',
        view: '海側（窓あり）',
        capacity: '2名',
        wide: '約16㎡',
        facility: 'バス、トイレ、羽毛布団、冷蔵庫、液晶テレビ、ドライヤー、空間除菌消臭装置、電気ケトル、アメニティセット',
        information: '最上級の充実した設備で心ゆくまで船旅をご堪能いただけます。',
        // default_count: 2,
        // extra_count: 1,
        max_count: 2,
        min_count: 2,
        reserve_count: 0,
    },
    {
        seat_type: SEAT_TYPE_P_TR,
        seat_type_name: 'プレミアムトリプル',
        grade: GRADE_P,
        room_type: '洋室トリプル',
        view: '海側（窓あり）',
        capacity: '3名',
        wide: '約16㎡',
        facility: 'バス、トイレ、羽毛布団、冷蔵庫、液晶テレビ、ドライヤー、空間除菌消臭装置、電気ケトル、アメニティセット',
        information: '最上級の充実した設備でご家族でのご旅行にもおすすめです。',
        // default_count: 3,
        // extra_count: 0,
        max_count: 3,
        min_count: 2,
        reserve_count: 3,
    },
    {
        seat_type: SEAT_TYPE_P_BA,
        seat_type_name: 'プレミアムバリアフリー',
        grade: GRADE_P,
        room_type: '洋室ツイン',
        view: '海側（窓あり）',
        capacity: '2名',
        wide: '約22㎡',
        facility: 'バス、トイレ、羽毛布団、冷蔵庫、液晶テレビ、ドライヤー、空間除菌消臭装置、電気ケトル、アメニティセット',
        information: '車いすをご利用のお客様にも配慮し、快適にご利用いただけるお部屋です。',
        // default_count: 2,
        // extra_count: 1,
        max_count: 2,
        min_count: 2,
        reserve_count: 0,
    },
    {
        seat_type: SEAT_TYPE_F_TW,
        seat_type_name: 'ファーストツイン',
        grade: GRADE_F,
        room_type: '洋室ツイン',
        view: '海側（窓あり）',
        capacity: '2名',
        wide: '	約10㎡',
        facility: 'トイレ、羽毛布団、液晶テレビ、ドライヤー、空間除菌消臭装置、電気ケトル、アメニティセット',
        information: 'ご夫婦やお友達とのご旅行にもおすすめの、定員2名様のお部屋です。',
        // default_count: 2,
        // extra_count: 0,
        max_count: 2,
        min_count: 2,
        reserve_count: 0,
    },
    {
        seat_type: SEAT_TYPE_F_FO,
        seat_type_name: 'ファーストフォース',
        grade: GRADE_F,
        room_type: '洋室2段ベッド',
        view: '海側（窓あり）',
        capacity: '4名',
        wide: '約14㎡',
        facility: 'トイレ、羽毛布団、液晶テレビ、ドライヤー、空間除菌消臭装置、電気ケトル、アメニティセット',
        information: 'ご家族でのご旅行にもおすすめ、階段付きの2段ベッド、定員4名様のお部屋です。',
        // default_count: 4,
        // extra_count: 0,
        max_count: 4,
        min_count: 3,
        reserve_count: 4,
    },
    {
        seat_type: SEAT_TYPE_FWP_TW,
        seat_type_name: 'ファーストwithペットツイン',
        grade: GRADE_F,
        room_type: '洋室ツイン',
        view: '海側（窓あり）',
        capacity: '2名',
        wide: '約12㎡',
        facility: 'トイレ、羽毛布団、液晶テレビ、ドライヤー、空間除菌消臭装置、電気ケトル、アメニティセット',
        information: '大切なペットと一緒にお過ごしいただけるお部屋です。',
        // default_count: 2,
        // extra_count: 0,
        max_count: 2,
        min_count: 2,
        reserve_count: 0,
    },
    {
        seat_type: SEAT_TYPE_FWP_FO,
        seat_type_name: 'ファーストwithペットフォース',
        grade: GRADE_F,
        room_type: '洋室2段ベッド',
        view: '海側（窓あり）',
        capacity: '4名',
        wide: '約12㎡',
        facility: 'トイレ、羽毛布団、液晶テレビ、ドライヤー、空間除菌消臭装置、電気ケトル、アメニティセット',
        information: '大切なペットと一緒にお過ごしいただけるお部屋です。',
        // default_count: 2,
        // extra_count: 2,
        max_count: 2,
        min_count: 2,
        reserve_count: 0,
    },
    {
        seat_type: SEAT_TYPE_S,
        seat_type_name: 'シングル',
        grade: GRADE_S,
        room_type: '洋室シングル',
        view: '内側（窓なし）',
        capacity: '1名',
        wide: '約3㎡',
        facility: '羽毛布団、液晶テレビ、使い捨てスリッパ、タオル',
        information: 'コンパクトなシングルルーム。ビジネス利用や一人旅におすすめです。\n一部日程限定で3階シングルのご案内となる場合がございます。',
        // default_count: 1,
        // extra_count: 0,
        max_count: 1,
        min_count: 1,
        reserve_count: 0,
    },
    {
        seat_type: SEAT_TYPE_DS,
        seat_type_name: 'ドライバーシングル',
        grade: GRADE_S,
        room_type: '洋室シングル',
        view: '内側（窓なし）',
        capacity: '1名',
        wide: '約3㎡',
        facility: '羽毛布団、液晶テレビ、使い捨てスリッパ、タオル',
        information: 'コンパクトなシングルルーム。ビジネス利用や一人旅におすすめです。',
        // default_count: 1,
        // extra_count: 0,
        max_count: 1,
        min_count: 1,
        reserve_count: 0,
    },
    {
        seat_type: SEAT_TYPE_D,
        seat_type_name: 'ドミトリー',
        grade: GRADE_D,
        room_type: '',
        view: '	内側（窓なし）',
        capacity: '252名',
        wide: '',
        facility: '羽毛布団',
        information: '階段付き2段ベッドで昇り降りしやすいお部屋です。',
        // default_count: 1,
        // extra_count: 0,
        max_count: 999,
        min_count: 1,
        reserve_count: 0,
    },
    {
        seat_type: SEAT_TYPE_T,
        seat_type_name: 'ツーリスト',
        grade: GRADE_T,
        room_type: '',
        view: '内側（窓なし）',
        capacity: '30名',
        wide: '',
        facility: '羽毛布団',
        information:
            '枕付きのマットレスをご準備した広間タイプのお部屋です。\n車いすをご利用のお客様にも配慮したバリアフリーのお部屋となっております。',
        // default_count: 1,
        // extra_count: 0,
        max_count: 999,
        min_count: 1,
        reserve_count: 0,
    },
] as RoomInformation[]

/**
 * ヌル（扱い）かどうか？
 * @param {*} target
 */
export function isNull(target: any): boolean {
    return target == null || target === void 0 || target === '' || target == undefined
}
/**
 * ログイン中か？
 */
export function isLogged(): boolean {
    return !isNull(localStorage.getItem('access'))
}
/**
 * ローカルストレージから認証トークン取得
 */
export function getAccessToken(): string {
    return localStorage.getItem('access') as string
}
/**
 * ユーザーIDを取得
 */
export function getId(): string {
    return localStorage.getItem('access') as string
}
/**
 * 氏名を姓と名に分割する
 * @param {*} fullname
 */
export function splitFullname(fullname: string): string[] {
    if (isNull(fullname)) {
        return ['', '']
    }
    const ret = fullname.split(/[ 　]/) // 半角・全角空白
    while (ret.length < 2) {
        ret.push('')
    }
    return ret
}

/**
 * 姓と名を結合する
 * @param {*} sei
 * @param {*} mei
 */
export function joinFirstNameAndLastName(sei: string | null | undefined, mei: string | null | undefined) {
    let ret = ''
    if (!isNull(sei)) {
        ret += sei
    }
    if (ret.length > 0) {
        ret += '　'
    }
    if (!isNull(mei)) {
        ret += mei
    }
    return ret
}

/**
 * 日付フォーマット
 * @param {*} date:日付
 * @param {*} zero_padding:0埋め対応
 * @param {*} join_type:0:'-'区切り, 1:'/'区切り, 2:年月日, 3:月日, 4:'/'区切り月日
 * @param {*} week_type:0:曜日無し, 1:日本語
 */
export const formatDate = (date: Date | null, zero_padding = true, join_type = 0, week_type = 0): string => {
    let y = ''
    let m = ''
    let d = ''
    let week = ''
    if (isNull(date)) {
        return ''
    }
    y = date!.getFullYear() + ''
    if (zero_padding) {
        m = ('00' + (date!.getMonth() + 1)).slice(-2)
        d = ('00' + date!.getDate()).slice(-2)
    } else {
        m = date!.getMonth() + 1 + ''
        d = date!.getDate() + ''
    }
    if (week_type == 1) {
        week = '（' + ['日', '月', '火', '水', '木', '金', '土'][date!.getDay()] + '）'
    }
    if (join_type == 0) {
        return `${y + '-' + m + '-' + d + week}`
    } else if (join_type == 1) {
        return `${y + '/' + m + '/' + d + week}`
    } else if (join_type == 2) {
        return `${y + '年' + m + '月' + d + '日' + week}`
    } else if (join_type == 3) {
        return `${m + '月' + d + '日' + week}`
    } else if (join_type == 4) {
        return `${m + '/' + d + week}`
    }
    return `${y + '-' + m + '-' + d + week}`
}
/**
 * 日時フォーマット
 * @param {*} date :日時
 * @param {*} zero_padding:0埋め対応
 * @param {*} join_type :0:'-:'区切り日時秒, 1:':'区切り時刻, 2:年月日時分秒, 3:時分
 */
export const formatDateTime = (date: Date | null, zero_padding = true, join_type = 0): string => {
    let y = ''
    let m = ''
    let d = ''
    let h = ''
    let mi = ''
    let s = ''
    if (isNull(date)) {
        return ''
    }
    if (zero_padding) {
        y = date!.getFullYear() + ''
        m = ('00' + (date!.getMonth() + 1)).slice(-2)
        d = ('00' + date!.getDate()).slice(-2)
        h = ('00' + date!.getHours()).slice(-2)
        mi = ('00' + date!.getMinutes()).slice(-2)
        s = ('00' + date!.getSeconds()).slice(-2)
    } else {
        y = date!.getFullYear() + ''
        m = date!.getMonth() + 1 + ''
        d = date!.getDate() + ''
        h = date!.getHours() + ''
        mi = date!.getMinutes() + ''
        s = date!.getSeconds() + ''
    }
    if (join_type == 0) {
        return `${y}-${m}-${d} ${h}:${mi}:${s}`
    } else if (join_type == 1) {
        return `${h}:${('00' + mi).slice(-2)}`
    } else if (join_type == 2) {
        return `${y}年${m}月${d}日 ${h}時${mi}分${s}秒`
    } else if (join_type == 3) {
        return `${h}時${mi}分`
    }
    return ''
}
// 来月取得
export const nextMonth = (date: Date, add_month = 1): Date => {
    const resultDate = new Date(date.getTime())
    resultDate.setMonth(date.getMonth() + add_month)
    if (date.getDate() > resultDate.getDate()) {
        resultDate.setDate(0)
    }
    return resultDate
}
// 先月取得
export const lastMonth = (date: Date): Date => {
    return new Date(date.getFullYear(), date.getMonth() - 1, date.getDate())
}
// 月初日取得
export const firstMonthDate = (date: Date): Date => {
    return new Date(date.getFullYear(), date.getMonth(), 1)
}
// 月末日取得
export const lastMonthDate = (date: Date): Date => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0)
}
// 日付の差を計算（基準日 - 計算対象日）
export const calcDiffDays = (baseDay: Date, targetDay: Date): number => {
    const baseDate = new Date(
        baseDay.toLocaleDateString('ja-JP', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        }),
    )
    const targetDate = new Date(
        targetDay.toLocaleDateString('ja-JP', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        }),
    )
    return Math.floor((baseDate.getTime() - targetDate.getTime()) / 86400000)
}
// 文字列型を日付型へ変更
export const castStringToDate = (date: string | null | undefined): Date | null => {
    let fix_date = date
    if (isNull(fix_date)) {
        return null
    }
    fix_date = fix_date!.replace('/', '-')
    fix_date = fix_date!.replace('年', '-').replace('月', '-').replace('日', '')
    let fix_dates = fix_date.split('-')
    if (fix_dates.length == 2) {
        fix_dates.push('1')
    }
    for (let i = 0; i < fix_dates.length; i++) {
        if (fix_dates[i].length == 1) {
            fix_dates[i] = '0' + fix_dates[i]
        }
    }
    fix_date = fix_dates.join('-')

    // 時刻なしは0:00:00として処理する
    if (fix_date.length == 10) {
        fix_date += ' 00:00:00'
    }

    return new Date(fix_date)
}
// 日付範囲配列を作成
export const getMakeDateRange = (startDay: Date | null, endDate: Date | null): Date[] => {
    let arr_date = [] as Date[]
    if (isNull(startDay) || isNull(endDate)) {
        return arr_date
    }
    const base_day = formatDate(startDay, false, 0)
    const diff_count = calcDiffDays(endDate!, startDay!)
    for (let i = 0; i <= diff_count; i++) {
        let idx_day = new Date(base_day)
        idx_day.setDate(idx_day.getDate() + i)
        arr_date.push(idx_day)
    }

    return arr_date
}
// 全角数字を半角数字へ変更
export const ZenNumToHanNum = (val: string | null): string => {
    if (isNull(val)) {
        return ''
    }
    return val!.replace(/[０-９]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    })
}

/**
 * 最低必要部屋数
 * @param seat_type :部屋タイプコード
 * @param passenger_adult_num :大人人数
 * @param passenger_child_num :小人人数
 * @param passenger_infant_num :幼児人数
 * @param passenger_baby_num :乳児人数
 * @param count_type :0:下等級は人数 1:下等級は1部屋扱い
 */
export const useMinRoomSeatCount = (
    seat_type: number,
    passenger_adult_num: number,
    passenger_child_num: number,
    passenger_infant_num: number,
    passenger_baby_num: number,
    count_type: number,
): number => {
    if (isNull(seat_type)) {
        return 0
    }
    // 部屋数(最低1部屋)
    let cabin_seat_count = 1
    // 1部屋当たり乗船者数
    const passenger_par_room = useMaxRoomPassengerCount(seat_type, count_type)
    // 有料乗船者数
    let paying_passenger_count = 0
    paying_passenger_count = paying_passenger_count + passenger_adult_num
    paying_passenger_count += passenger_child_num
    if (passenger_adult_num < passenger_infant_num) {
        paying_passenger_count += passenger_adult_num - passenger_infant_num
    }
    cabin_seat_count = Math.ceil(paying_passenger_count / passenger_par_room)

    return cabin_seat_count
}
/**
 * 最大乗船者数
 * @param seat_type :部屋タイプコード
 * @param count_type :0:下等級は人数 1:下等級は1部屋扱い
 * @returns 1部屋当たり最大人数
 */
export const useMaxRoomPassengerCount = (seat_type: number, count_type: number): number => {
    // 1部屋当たり最大乗船者数
    let passenger_par_room = 1
    switch (seat_type) {
        case SEAT_TYPE_P_TW:
        case SEAT_TYPE_P_BA:
        case SEAT_TYPE_F_TW:
        case SEAT_TYPE_FWP_TW:
            passenger_par_room = 2
            break
        case SEAT_TYPE_P_TR:
            passenger_par_room = 3
            break
        case SEAT_TYPE_F_FO:
        case SEAT_TYPE_FWP_FO:
            passenger_par_room = 4
            break
        case SEAT_TYPE_S:
        case SEAT_TYPE_DS:
            passenger_par_room = 1
            break
        case SEAT_TYPE_D:
        case SEAT_TYPE_T:
            if (count_type == 0) {
                passenger_par_room = 1
            } else if (count_type == 1) {
                passenger_par_room = 9999
            }
            break
    }
    return passenger_par_room
}

// 成約情報取得
export async function getContract(contract_number: string, dep_day: string, sea_route_code: string): Promise<Contract> {
    const contract = {} as Contract
    const postData = {
        contract_number: contract_number,
        sea_route_code: sea_route_code,
    }
    await apiClient
        .post(`${API_ORDER}/contract`, postData)
        .then((res: AxiosResponse<any>) => {
            const { status, data } = res
            contract.contract_number = data.contract_number
            contract.contract_name = data.contract_name
            contract.sub_title = data.sub_title
            contract.start_date = isNull(data.start_date) ? null : castStringToDate(data.start_date)
            contract.end_date = isNull(data.end_date) ? null : castStringToDate(data.end_date)
            contract.supplier_joined_code = data.supplier_joined_code
            contract.creation_name = data.creation_name
            contract.person = data.person
            contract.tel = data.tel
            contract.fax = data.fax
            contract.department_code = data.department_code
            contract.sales_person = data.sales_person
            contract.payment_type = data.payment_type
            contract.price_type = data.price_type
            contract.sales_close_days = data.sales_close_days
            contract.campaign_discount = data.campaign_discount
            contract.campaign_name = data.campaign_name
            contract.discount_rate = data.discount_rate
            contract.rounding_unit = data.rounding_unit
            contract.rounding_rate = data.rounding_rate
            contract.discount_id = data.discount_id
            contract.divide_type_id = data.divide_type_id
            contract.sales_start_date = isNull(data.sales_start_date) ? data.sales_start_date : castStringToDate(data.sales_start_date)
            contract.sales_end_date = isNull(data.sales_end_date) ? data.sales_end_date : castStringToDate(data.sales_end_date)
            contract.use_booking_people_num_min = data.use_booking_people_num_min
            contract.booking_people_num_min = data.booking_people_num_min
            contract.use_booking_people_num_max = data.use_booking_people_num_max
            contract.booking_people_num_max = data.booking_people_num_max
            contract.use_booking_age_limit = data.use_booking_age_limit
            contract.booking_age_limit_min = data.booking_age_limit_min
            contract.booking_age_limit_max = data.booking_age_limit_max
            contract.prefectures_restrict = data.prefectures_restrict
            contract.gender_restrict = data.gender_restrict
            contract.one_way_restrict = data.one_way_restrict
            contract.vehicle_restrict = data.vehicle_restrict
            contract.child_restrict = data.child_restrict
            contract.pets_restrict = data.pets_restrict
            contract.counter_payment_restrict = data.counter_payment_restrict
            contract.dep_date_change_limit = data.dep_date_change_limit
            contract.dep_date_change_limit_days = data.dep_date_change_limit_days
            contract.dep_date_change_limit_time = data.dep_date_change_limit_time
            contract.passenger_change_limit = data.passenger_change_limit
            contract.passenger_change_limit_days = data.passenger_change_limit_days
            contract.passenger_change_limit_time = data.passenger_change_limit_time
            contract.boarding_car_change_limit = data.boarding_car_change_limit
            contract.boarding_car_change_limit_days = data.boarding_car_change_limit_days
            contract.boarding_car_change_limit_time = data.boarding_car_change_limit_time
            contract.information = data.information
            contract.precautions = data.precautions
            contract.discontinued = data.discontinued
            contract.cancel_policy = []
            if (data.cancel_policy) {
                const parseData = JSON.parse(data.cancel_policy)['cancel_policy']
                const cancelPolicy = parseData.sort((a: any, b: any) => b.day - a.day)
                let old_day = null

                for (let i = 0; i < cancelPolicy.length; i++) {
                    const formatPolicy = formatCancelPolicy(
                        cancelPolicy[i]['type'],
                        cancelPolicy[i]['day'],
                        old_day,
                        cancelPolicy[i]['passenger'],
                        cancelPolicy[i]['vehicle'],
                    )

                    contract.cancel_policy.push({
                        type: cancelPolicy[i]['type'],
                        day: cancelPolicy[i]['day'],
                        passenger: cancelPolicy[i]['passenger'],
                        vehicle: cancelPolicy[i]['vehicle'],
                        disp_day: formatPolicy[0],
                        disp_value: formatPolicy[1],
                    } as CancelPolicyDetail)
                    old_day = cancelPolicy[i]['day'] - 1
                }
            }
            contract.pet_room_amount = data.pet_room_amount
            contract.reserved_change_start_date = isNull(data.reserved_change_start_date)
                ? null
                : castStringToDate(formatDate(castStringToDate(data.reserved_change_start_date)))
            contract.reserved_change_end_date = isNull(data.reserved_change_end_date)
                ? null
                : castStringToDate(formatDate(castStringToDate(data.reserved_change_end_date)))
            contract.fare_passengers = []
            for (let i = 0; i < data.fare_passengers.length; i++) {
                contract.fare_passengers.push({
                    type: data.fare_passengers[i].type,
                    driver: data.fare_passengers[i].driver,
                    grade: data.fare_passengers[i].grade,
                    fare: data.fare_passengers[i].fare,
                    baf: data.fare_passengers[i].baf,
                    baf_in_fare: data.fare_passengers[i].baf_in_fare,
                } as FarePassenger)
            }
            contract.fare_vehicles = []
            for (let i = 0; i < data.fare_vehicles.length; i++) {
                contract.fare_vehicles.push({
                    code: data.fare_vehicles[i].code,
                    length: data.fare_vehicles[i].length,
                    fare: data.fare_vehicles[i].fare,
                    baf: data.fare_vehicles[i].baf,
                    baf_in_fare: data.fare_vehicles[i].baf_in_fare,
                } as FareVehicle)
            }
            contract.fare_reserved = []
            for (let i = 0; i < data.fare_reserved.length; i++) {
                contract.fare_reserved.push({
                    grade: data.fare_reserved[i].grade,
                    amount: data.fare_reserved[i].amount,
                } as FareReserved)
            }
        })
        .catch((e: AxiosError<{ error: string }>) => {
            // エラー処理
            console.log(e.message)
        })
    return contract
}
// 乗船成約情報取得
export async function getBoardingContract(boarding_no: string, sea_route_code: string): Promise<Contract> {
    const contract = {} as Contract
    const postData = {
        boarding_no: boarding_no,
        sea_route_code: sea_route_code,
    }
    await apiClient
        .post(`${API_ORDER}/boarding/contract`, postData)
        .then((res: AxiosResponse<any>) => {
            const { status, data } = res
            contract.contract_number = data.contract_number
            contract.contract_name = data.contract_name
            contract.sub_title = data.sub_title
            contract.start_date = data.start_date
            contract.end_date = data.end_date
            contract.supplier_joined_code = data.supplier_joined_code
            contract.creation_name = data.creation_name
            contract.person = data.person
            contract.tel = data.tel
            contract.fax = data.fax
            contract.department_code = data.department_code
            contract.sales_person = data.sales_person
            contract.payment_type = data.payment_type
            contract.price_type = data.price_type
            contract.sales_close_days = data.sales_close_days
            contract.campaign_discount = data.campaign_discount
            contract.campaign_name = data.campaign_name
            contract.discount_rate = data.discount_rate
            contract.rounding_unit = data.rounding_unit
            contract.rounding_rate = data.rounding_rate
            contract.discount_id = data.discount_id
            contract.divide_type_id = data.divide_type_id
            contract.sales_start_date = isNull(data.sales_start_date) ? data.sales_start_date : castStringToDate(data.sales_start_date)
            contract.sales_end_date = isNull(data.sales_end_date) ? data.sales_end_date : castStringToDate(data.sales_end_date)
            contract.use_booking_people_num_min = data.use_booking_people_num_min
            contract.booking_people_num_min = data.booking_people_num_min
            contract.use_booking_people_num_max = data.use_booking_people_num_max
            contract.booking_people_num_max = data.booking_people_num_max
            contract.use_booking_age_limit = data.use_booking_age_limit
            contract.booking_age_limit_min = data.booking_age_limit_min
            contract.booking_age_limit_max = data.booking_age_limit_max
            contract.prefectures_restrict = data.prefectures_restrict
            contract.gender_restrict = data.gender_restrict
            contract.one_way_restrict = data.one_way_restrict
            contract.vehicle_restrict = data.vehicle_restrict
            contract.child_restrict = data.child_restrict
            contract.pets_restrict = data.pets_restrict
            contract.counter_payment_restrict = data.counter_payment_restrict
            contract.dep_date_change_limit = data.dep_date_change_limit
            contract.dep_date_change_limit_days = data.dep_date_change_limit_days
            contract.dep_date_change_limit_time = data.dep_date_change_limit_time
            contract.passenger_change_limit = data.passenger_change_limit
            contract.passenger_change_limit_days = data.passenger_change_limit_days
            contract.passenger_change_limit_time = data.passenger_change_limit_time
            contract.boarding_car_change_limit = data.boarding_car_change_limit
            contract.boarding_car_change_limit_days = data.boarding_car_change_limit_days
            contract.boarding_car_change_limit_time = data.boarding_car_change_limit_time
            contract.information = data.information
            contract.precautions = data.precautions
            contract.discontinued = data.discontinued
            contract.cancel_policy = []
            if (data.cancel_policy) {
                const parseData = JSON.parse(data.cancel_policy)['cancel_policy']
                const cancelPolicy = parseData.sort((a: any, b: any) => b.day - a.day)
                let old_day = null

                for (let i = 0; i < cancelPolicy.length; i++) {
                    const formatPolicy = formatCancelPolicy(
                        cancelPolicy[i]['type'],
                        cancelPolicy[i]['day'],
                        old_day,
                        cancelPolicy[i]['passenger'],
                        cancelPolicy[i]['vehicle'],
                    )

                    contract.cancel_policy.push({
                        type: cancelPolicy[i]['type'],
                        day: cancelPolicy[i]['day'],
                        passenger: cancelPolicy[i]['passenger'],
                        vehicle: cancelPolicy[i]['vehicle'],
                        disp_day: formatPolicy[0],
                        disp_value: formatPolicy[1],
                    } as CancelPolicyDetail)
                    old_day = cancelPolicy[i]['day'] - 1
                }
            }
            contract.pet_room_amount = data.pet_room_amount
            contract.fare_passengers = []
            for (let i = 0; i < data.fare_passengers.length; i++) {
                contract.fare_passengers.push({
                    type: data.fare_passengers[i].type,
                    driver: data.fare_passengers[i].driver,
                    grade: data.fare_passengers[i].grade,
                    fare: data.fare_passengers[i].fare,
                    baf: data.fare_passengers[i].baf,
                    baf_in_fare: data.fare_passengers[i].baf_in_fare,
                } as FarePassenger)
            }
            contract.fare_vehicles = []
            for (let i = 0; i < data.fare_vehicles.length; i++) {
                contract.fare_vehicles.push({
                    code: data.fare_vehicles[i].code,
                    length: data.fare_vehicles[i].length,
                    fare: data.fare_vehicles[i].fare,
                    baf: data.fare_vehicles[i].baf,
                    baf_in_fare: data.fare_vehicles[i].baf_in_fare,
                } as FareVehicle)
            }
            contract.fare_reserved = []
            for (let i = 0; i < data.fare_reserved.length; i++) {
                contract.fare_reserved.push({
                    grade: data.fare_reserved[i].grade,
                    amount: data.fare_reserved[i].amount,
                } as FareReserved)
            }
        })
        .catch((e: AxiosError<{ error: string }>) => {
            // エラー処理
            console.log(e.message)
        })

    return contract
}
// 成約料金計算
export function calcContractPrice(
    contract: ContractFare,
    passenger_adult_num: number,
    passenger_child_num: number,
    passenger_baby_num: number,
    passenger_infant_num: number,
    car_3m_num: number,
    car_4m_num: number,
    car_5m_num: number,
    car_6m_num: number,
    car_bicycle_num: number,
    car_two_wheel_small_num: number,
    car_two_wheel_medium_num: number,
    car_two_wheel_large_num: number,
): number {
    let price = 0
    const car_num = car_3m_num + car_4m_num + car_5m_num + car_6m_num
    // 乗船者：大人
    if (!isNull(contract.adult)) {
        if (!isNull(contract.driver) || contract.driver == 0) {
            // ドライバーあり
            price += car_num * contract.driver
            price += (passenger_adult_num - car_num) * contract.adult
        } else {
            // ドライバー無し
            price += passenger_adult_num * contract.adult
        }
    }
    // 乗船者：小人
    if (!isNull(contract.child)) {
        price += passenger_child_num * contract.child
    }
    // 乗船者：幼児
    // 乗船者：乳児

    // 乗用車：～3m
    if (!isNull(contract.car_3m)) {
        price += car_3m_num * contract.car_3m
    }
    // 乗用車：～4m
    if (!isNull(car_4m_num) && !isNull(contract.car_4m)) {
        price += car_4m_num * contract.car_4m
    }
    // 乗用車：～5m
    if (!isNull(car_5m_num) && !isNull(contract.car_5m)) {
        price += car_5m_num * contract.car_5m
    }
    // 乗用車：6m～
    if (!isNull(car_6m_num) && !isNull(contract.car_6m)) {
        price += car_6m_num * contract.car_6m
    }

    // 2輪：自転車
    if (!isNull(contract.bicycle)) {
        price += car_bicycle_num! * contract.bicycle
    }
    // 2輪：小型
    if (!isNull(contract.bicycle_little)) {
        price += car_two_wheel_small_num! * contract.bicycle_little
    }
    // 2輪：中型
    if (!isNull(contract.bicycle_middle)) {
        price += car_two_wheel_medium_num! * contract.bicycle_middle
    }
    // 2輪：大型
    if (!isNull(contract.bicycle_large)) {
        price += car_two_wheel_large_num! * contract.bicycle_large
    }

    return price
}
// キャンセルポリシーフォーマット
export function formatCancelPolicy(type: number, day: number, old_day: any, passenger: number, vehicle: number): string[] {
    const format_value = ['', '']
    if (old_day == null) {
        format_value[0] = `予約完了から出航日の${day}日前まで`
    } else if (day == -1) {
        format_value[0] = '出航後'
    } else if (day == 0 && old_day != 0) {
        format_value[0] = `出航${old_day}日前から出航時まで`
    } else if (day == 0 && old_day == 0) {
        format_value[0] = '出航時まで'
    } else if (day == 1 && old_day == 1) {
        format_value[0] = '出航1日前'
    } else if (day == 999) {
        format_value[0] = '期間外'
    } else {
        format_value[0] = `出航${old_day}日前から${day}日前まで`
    }

    if (type == 0) {
        format_value[1] = '乗船者：' + passenger * 100 + '％、乗用車・二輪車：' + vehicle * 100 + '％'
    } else {
        format_value[1] = '乗船者：' + passenger.toLocaleString() + '円、乗用車・二輪車：' + vehicle + '円'
    }

    return format_value
}
// HTMLにフォーマット
export function formatHtml(value: string | null | undefined): string {
    if (isNull(value)) return ''
    return value!.replace(/\n/g, '<br>')
}

// 同乗者マスタ設定
export async function setCompanionMaster(mcf_member_id: string): Promise<void> {
    const companions = [] as Array<CompanionDetail>
    await apiClient
        .get(`${API_USERS}/companion`)
        .then((res: AxiosResponse<any>) => {
            const { status, data } = res
            for (let i = 0; i < data.length; i++) {
                companions.push({
                    id: data[i].id,
                    passenger_type: data[i].passenger_type,
                    family_name_katakana: data[i].family_name_katakana,
                    given_name_katakana: data[i].given_name_katakana,
                    sex: data[i].sex,
                } as CompanionDetail)
            }
        })
        .catch((e: AxiosError<{ error: string }>) => {
            // エラー処理
            console.log(e.message)
        })

    setCompanionData(companions)
}
// 同乗者情報取得
export function getCompanionDetail(companion_id: number): CompanionDetail | null {
    const companions = getCompanionData()
    for (let i = 0; i < companions.length; i++) {
        if (companions[i].id == companion_id) {
            return companions[i]
        }
    }
    return null
}
// 同乗者情報更新
export async function updCompanionBooking(companions: any[]): Promise<number> {
    let err_count = 0

    if (companions.length > 0) {
        const postData = {
            companion: companions,
        }
        await apiClient
            .post(`${API_USERS}/companion`, postData)
            .then((res: AxiosResponse<any>) => {
                const { status, data } = res
            })
            .catch((e: AxiosError<{ error: string }>) => {
                // エラー処理
                err_count++
                console.log(e.message)
            })
    }

    return err_count
}
// 車両マスタ設定
export async function setVehicleMaster(mcf_member_id: string): Promise<void> {
    const postData = {}
    const vehicles = [] as Array<VehicleDetail>
    await apiClient
        .get(`${API_USERS}/vehicle`, { params: postData })
        .then((res: AxiosResponse<any>) => {
            const { status, data } = res
            for (let i = 0; i < data.car.length; i++) {
                vehicles.push({
                    id: data.car[i].id,
                    car_name: data.car[i].car_name,
                    car_type_code: data.car[i].car_type_code,
                    car_length: data.car[i].length,
                    car_place_name: data.car[i].place_name,
                    car_class_number: data.car[i].class_number,
                    car_hiragana: data.car[i].hiragana,
                    car_number: data.car[i].number,
                } as VehicleDetail)
            }
            for (let i = 0; i < data.bicycle.length; i++) {
                vehicles.push({
                    id: data.bicycle[i].id,
                    car_name: data.bicycle[i].car_name,
                    car_type_code: data.bicycle[i].car_type_code,
                    car_length: data.bicycle[i].length,
                    car_place_name: data.bicycle[i].place_name,
                    car_class_number: data.bicycle[i].class_number,
                    car_hiragana: data.bicycle[i].hiragana,
                    car_number: data.bicycle[i].number,
                } as VehicleDetail)
            }
        })
        .catch((e: AxiosError<{ error: string }>) => {
            // エラー処理
            console.log(e.message)
        })

    setVehicleData(vehicles)
}
// 車両情報取得
export function getVehicleDetail(vehicles_id: number): VehicleDetail | null {
    const vehicles = getVehicleData()
    for (let i = 0; i < vehicles.length; i++) {
        if (vehicles[i].id == vehicles_id) {
            return vehicles[i]
        }
    }
    return null
}
// 会員情報設定
export async function setMemberDetail(): Promise<void> {
    let member_detail = {} as MemberDetail
    await apiClient
        .get(`${API_USERS}/member`)
        .then((res: AxiosResponse<any>) => {
            const { status, data } = res
            member_detail = {
                member_id: data.member_id, // ログインID
                family_name: data.family_name, // 姓
                given_name: data.given_name, // 名
                family_name_katakana: data.family_name_katakana, // セイ
                given_name_katakana: data.given_name_katakana, // メイ
                tel1: data.tel1, // 電話番号_市外局番
                tel2: data.tel2, // 電話番号_市内局番
                tel3: data.tel3, // 電話番号_回線番号
                post_code: data.post_code, // 郵便番号
                prefectures: data.prefectures, // 都道府県
                address: data.address, // 住所
                sex: data.sex, // 性別
                birthday_year: data.birthday_year, // 生年月日_年
                birthday_month: data.birthday_month, // 生年月日_月
                birthday_day: data.birthday_day, // 生年月日_日
                terms_confirm_datetime: data.terms_confirm_datetime, // 規約バージョン
                privacy_policy_confirm_datetime: data.privacy_policy_confirm_datetime, // プライバシーポリシー同意日時
                email: data.email, // メールアドレス
                mail_magazine_request: data.mail_magazine_request, // メールマガジン希望
            } as MemberDetail
        })
        .catch((e: AxiosError<{ error: string }>) => {
            // エラー処理
            console.log(e.message)
        })
    setMemberData(member_detail)
}
// ストア会員情報更新
export function setStoreLoginStateFromMemberData(): void {
    const store = useStore()
    const member_detail = getMemberData()
    if (!isNull(member_detail)) {
        store.dispatch('setMemberId', member_detail.member_id)
        store.dispatch('setFamilyName', member_detail.family_name)
        store.dispatch('setGivenName', member_detail.given_name)
        store.dispatch('setEmail', member_detail.email)
        store.dispatch('setIsLoggedIn', !isNull(member_detail.member_id))
    } else {
        store.dispatch('setMemberId', '')
        store.dispatch('setFamilyName', '')
        store.dispatch('setGivenName', '')
        store.dispatch('setEmail', '')
        store.dispatch('setIsLoggedIn', false)
    }
}
// 年齢計算
export function calcAge(dep_date: string, birthday_year: number, birthday_month: number, birthday_day: number): number | null {
    // 乗船日
    const target_dep_date = new Date(dep_date)
    // 乗船年の誕生日
    const target_birthday = new Date(target_dep_date.getFullYear(), birthday_month - 1, birthday_day)
    if (isNaN(target_dep_date.getDate()) || isNaN(target_birthday.getDate())) {
        return null
    }
    //年齢
    const target_age = target_dep_date.getFullYear() - birthday_year
    if (target_dep_date < target_birthday) {
        //乗船年まだ誕生日が来ていない
        return target_age - 1
    } else {
        return target_age
    }
}
export function calcAgeToday(birthday: Date | null): number | null {
    if (isNull(birthday)) {
        return null
    }
    // 今日
    const target_dep_date = new Date()
    // 今年の誕生日
    const target_birthday = new Date(target_dep_date.getFullYear(), birthday!.getMonth(), birthday!.getDay())
    if (isNaN(target_dep_date.getDate()) || isNaN(target_birthday.getDate())) {
        return null
    }
    //年齢
    const target_age = target_dep_date.getFullYear() - birthday!.getFullYear()
    if (target_dep_date < target_birthday) {
        //乗船年まだ誕生日が来ていない
        return target_age - 1
    } else {
        return target_age
    }
}
// 乗船者区分取得（年齢から）
export function getPassengerTypeByAge(age: number | null): number | null {
    if (age == null || age < 0) {
        return null
    } else if (age == 0) {
        return PASSENGER_TYPE_BABY
    } else if (age <= 6) {
        return PASSENGER_TYPE_INFANT
    } else if (age <= 12) {
        return PASSENGER_TYPE_CHILD
    } else {
        return PASSENGER_TYPE_ADULT
    }
}
// 乗船者区分判定（年齢から）
export function checkPassengerTypeByAge(passenger_type: number, age: number): boolean {
    if (passenger_type == PASSENGER_TYPE_ADULT) {
        return age >= 12
    } else if (passenger_type == PASSENGER_TYPE_CHILD) {
        // 小人は有料幼児を含むので下限1歳
        return age >= 1 && age <= 12
    } else if (passenger_type == PASSENGER_TYPE_INFANT) {
        return age >= 1 && age <= 6
    } else if (passenger_type == PASSENGER_TYPE_BABY) {
        return age == 0
    }
    return false
}
// 乗船者区分年齢範囲判定
export function checkPassengerTypeAgeRange(passenger_type: number | null, age: number | null): string {
    if (isNull(passenger_type)) return ''
    if (isNull(age)) return ''

    // 年齢と旅客区分の組み合わせチェック
    const age_range = RANGE_OF_PASSENGER_AGES[passenger_type!]
    if ((age_range[0] != null && age! < age_range[0]) || (age_range[1] != null && age! > age_range[1])) {
        return `${age}才の乗船者は${PASSENGER_TYPES[passenger_type!].text}を指定できません。`
    }
    return ''
}

// 郵便番号から住所確認
export async function searchAddress(post_code: string): Promise<AddressDetail> {
    console.log('↓↓↓util.searchAddress↓↓↓')
    const address = {
        extended: '',
        region_id: '',
        region: '',
        locality: '',
        street: '',
    } as AddressDetail

    await new YubinBango.Core(post_code, async function (value: any) {
        address.extended = value.extended
        address.region_id = ('00' + value.region_id).slice(-2) // 都道府県ID
        address.region = value.region // 都道府県
        address.locality = value.locality // 市区町村
        address.street = value.street // 町域
    })
    await delay(1000)
    console.log('↑↑↑util.searchAddress↑↑↑')
    return address
}
// 部屋タイプ名称取得
export function getSeatTypeName(seat_type: number | undefined): string {
    if (isNull(seat_type)) {
        return ''
    }

    if (seat_type != undefined && seat_type < 10) {
        return ''
    }

    return convertText(SEAT_TYPES, seat_type)
}
// 航路名称取得
export function getSeaRouteCodeName(sea_route_code: string | null | undefined): string {
    if (isNull(sea_route_code)) {
        return ''
    }
    if (sea_route_code == SEA_ROUTE_CODE_MIYAZAKI) {
        return '宮崎　→　神戸'
    }
    if (sea_route_code == SEA_ROUTE_CODE_KOBE) {
        return '神戸　→　宮崎'
    }
    return ''
}
// 車両区分名称取得
export function getCarTypeName(car_type_code: string): string {
    if (isNull(car_type_code)) {
        return ''
    }
    if (car_type_code == CAR_TYPE_PASSENGER) {
        return '乗用車'
    } else if (car_type_code == CAR_TYPE_TWO_WHEEL_LARGE) {
        return '750cc以上'
    } else if (car_type_code == CAR_TYPE_TWO_WHEEL_MEDIUM) {
        return '125cc〜750cc未満'
    } else if (car_type_code == CAR_TYPE_TWO_WHEEL_SMALL) {
        return '125cc未満'
    } else if (car_type_code == CAR_TYPE_BICYCLE) {
        return '自転車'
    }
    return ''
}
// 決済方法コード取得
export function getPaymentMethod(payment_method: string): number | null {
    if (payment_method == convertText(AUTHORI_PAYMENT_METHODS, AUTHORI_PAYMENT_METHOD_CREDIT)) {
        return AUTHORI_PAYMENT_METHOD_CREDIT
    } else if (payment_method == convertText(AUTHORI_PAYMENT_METHODS, AUTHORI_PAYMENT_METHOD_PAY_EASY)) {
        return AUTHORI_PAYMENT_METHOD_PAY_EASY
    } else if (payment_method == convertText(AUTHORI_PAYMENT_METHODS, AUTHORI_PAYMENT_METHOD_CONVENIENCE_STORE)) {
        return AUTHORI_PAYMENT_METHOD_CONVENIENCE_STORE
    } else if (payment_method == 'クレジット') {
        return AUTHORI_PAYMENT_METHOD_CREDIT
    } else if (payment_method == 'コンビニ') {
        return AUTHORI_PAYMENT_METHOD_CONVENIENCE_STORE
    }
    return null
}

// 運航情報取得
export async function getShipSchedule(dep_date: string, sea_route_code: string): Promise<ShipSchedule> {
    const ship_schedule = {} as ShipSchedule
    const postData = {
        dep_date: dep_date,
        sea_route_code: sea_route_code,
    }
    if (!isNull(dep_date) && !isNull(sea_route_code)) {
        await apiClient
            .post(`${API_ORDER}/schedule`, postData)
            .then((res: AxiosResponse<any>) => {
                const { status, data } = res
                ship_schedule.dep_date = dep_date
                ship_schedule.dep_time = data.dep_time
                ship_schedule.sea_route_code = sea_route_code
                ship_schedule.ship_code = data.ship_code
                ship_schedule.ship_name = data.ship_name
                ship_schedule.dep_status = data.dep_status
                ship_schedule.warnings = data.warnings
            })
            .catch((e: AxiosError<{ error: string }>) => {
                // エラー処理
                console.log(e.message)
            })
    }
    return ship_schedule
}

// 処理待ち
export async function delay(n: number) {
    return new Promise(function (resolve) {
        setTimeout(resolve, n)
    })
}

// 成約運賃取得(乗船者)
export async function getPassengerFare(
    dep_date: string,
    sea_route_code: string,
    contract_number: string,
    room_grade: number,
    passenger_type: number,
    driver_flg: boolean,
    with_pets_flg: boolean,
): Promise<ContractPassengerFare> {
    const contract_fare = {} as ContractPassengerFare
    const postData = {
        depDate: dep_date,
        seaRouteCode: sea_route_code,
        contractNumber: contract_number,
        grade: room_grade,
        passengerType: passenger_type,
        driverFlg: driver_flg,
        withPetsFlg: with_pets_flg,
    }
    await apiClient
        .post(`${API_ORDER}/fare/passenger`, postData)
        .then((res: AxiosResponse<any>) => {
            const { status, data } = res
            contract_fare.person_fare = data.person_fare
            contract_fare.baf = data.baf
            contract_fare.baf_in_person_fare = data.baf_in_person_fare
            contract_fare.with_pets_amount = data.with_pets_amount
            contract_fare.regular_fare = data.regular_fare
            contract_fare.regular_baf = data.regular_baf
            contract_fare.regular_with_pets = data.regular_with_pets
        })
        .catch((e: AxiosError<{ error: string }>) => {
            // エラー処理
            console.log(e.message)
        })

    return contract_fare
}
// 成約運賃取得(乗用車)
export async function getCarFare(dep_date: string, sea_route_code: string, contract_number: string, car_length: number): Promise<ContractCarFare> {
    const contract_fare = {} as ContractCarFare
    const postData = {
        depDate: dep_date,
        seaRouteCode: sea_route_code,
        contractNumber: contract_number,
        carLength: car_length,
    }
    await apiClient
        .post(`${API_ORDER}/fare/car`, postData)
        .then((res: AxiosResponse<any>) => {
            const { status, data } = res
            contract_fare.person_fare = data.person_fare
            contract_fare.baf = data.baf
            contract_fare.baf_in_person_fare = data.baf_in_person_fare
            contract_fare.contract_with_pets = data.contract_with_pets
            contract_fare.regular_fare = data.regular_fare
            contract_fare.regular_baf = data.regular_baf
            contract_fare.regular_with_pets = data.regular_with_pets
        })
        .catch((e: AxiosError<{ error: string }>) => {
            // エラー処理
            console.log(e.message)
        })

    return contract_fare
}
// 成約運賃取得(2輪)
export async function getTwoWheelFare(
    dep_date: string,
    sea_route_code: string,
    contract_number: string,
    car_type_code: string,
): Promise<ContractTwoWheelFare> {
    const contract_fare = {} as ContractTwoWheelFare
    const postData = {
        depDate: dep_date,
        seaRouteCode: sea_route_code,
        contractNumber: contract_number,
        carTypeCode: car_type_code,
    }
    await apiClient
        .post(`${API_ORDER}/fare/two_wheel`, postData)
        .then((res: AxiosResponse<any>) => {
            const { status, data } = res
            contract_fare.person_fare = data.person_fare
            contract_fare.baf = data.baf
            contract_fare.baf_in_person_fare = data.baf_in_person_fare
            contract_fare.contract_with_pets = data.contract_with_pets
            contract_fare.regular_fare = data.regular_fare
            contract_fare.regular_baf = data.regular_baf
            contract_fare.regular_with_pets = data.regular_with_pets
        })
        .catch((e: AxiosError<{ error: string }>) => {
            // エラー処理
            console.log(e.message)
        })
    return contract_fare
}
// 成約運賃取得(貸切)
export async function getReservedFare(
    dep_date: string,
    sea_route_code: string,
    contract_number: string,
    room_grade: number,
    room_seat_type: number,
    paid_passenger_num: number,
): Promise<ContractReserved> {
    const contract_fare = {} as ContractReserved
    const postData = {
        depDate: dep_date,
        seaRouteCode: sea_route_code,
        contractNumber: contract_number,
        grade: room_grade,
        seat_type: room_seat_type,
        paidPassengerNum: paid_passenger_num,
    }
    await apiClient
        .post(`${API_ORDER}/fare/reserved`, postData)
        .then((res: AxiosResponse<any>) => {
            const { status, data } = res
            contract_fare.person_fare = data.person_fare
            contract_fare.regular_fare = data.regular_fare
            contract_fare.regular_baf = data.regular_baf
        })
        .catch((e: AxiosError<{ error: string }>) => {
            // エラー処理
            console.log(e.message)
        })

    return contract_fare
}
// 振込手数料取得
export async function getTransferCharge(dep_date: string): Promise<number> {
    const postData = {
        depDate: dep_date,
    }
    const charge = await apiClient
        .post(`${API_ORDER}/fare/transfer_charge`, postData)
        .then((res: AxiosResponse<any>) => {
            const { status, data } = res
            return data.transfer_charge_fee
        })
        .catch((e: AxiosError<{ error: string }>) => {
            // エラー処理
            console.log(e.message)
            return 0
        })
    return charge
}

/**
 * 在庫の確認（プレミアム、ファースト、シングル）
 * @param divide_type_id 振分分類ID
 * @param seat_type 部屋タイプ
 * @param sea_route_code 航路コード
 * @param start_date 開始日
 * @param end_date 終了日
 * @param room_count 部屋数
 * @returns 在庫情報
 */
export async function getStockCabin(
    divide_type_id: number,
    seat_type: number,
    sea_route_code: string,
    start_date: string,
    end_date: string,
    room_count: number,
): Promise<StockDetail> {
    const stock_detail = {} as StockDetail
    const postData = {
        divide_type_id: divide_type_id,
        seat_type: seat_type,
        sea_route_code: sea_route_code,
        start_date: start_date,
        end_date: end_date,
        room_count: room_count,
    }
    await apiClient
        .post(`${API_ORDER}/stock/cabin`, postData)
        .then((res: AxiosResponse<any>) => {
            const { status, data } = res
            stock_detail.ship_schedule_id = data[0].ship_schedule_id
            stock_detail.dep_date = data[0].dep_date
            stock_detail.empty_status = data[0].empty_status
        })
        .catch((e: AxiosError<{ error: string }>) => {
            // エラー処理
            console.log(e.message)
        })

    return stock_detail
}
/**
 * 在庫の確認（ドミトリー、ツーリスト）
 * @param divide_type_id 振分分類ID
 * @param seat_type 部屋タイプ
 * @param sea_route_code 航路コード
 * @param start_date 開始日
 * @param end_date 終了日
 * @param people_count 有料乗船者人数
 * @returns 在庫情報
 */
export async function getStockSeat(
    divide_type_id: number,
    seat_type: number,
    sea_route_code: string,
    start_date: string,
    end_date: string,
    people_count: number,
): Promise<StockDetail> {
    const stock_detail = {} as StockDetail
    const postData = {
        divide_type_id: divide_type_id,
        seat_type: seat_type,
        sea_route_code: sea_route_code,
        start_date: start_date,
        end_date: end_date,
        people_count: people_count,
    }
    await apiClient
        .post(`${API_ORDER}/stock/seat`, postData)
        .then((res: AxiosResponse<any>) => {
            const { status, data } = res
            stock_detail.ship_schedule_id = data[0].ship_schedule_id
            stock_detail.dep_date = data[0].dep_date
            stock_detail.empty_status = data[0].empty_status
        })
        .catch((e: AxiosError<{ error: string }>) => {
            // エラー処理
            console.log(e.message)
        })

    return stock_detail
}
/**
 * 在庫の確認（乗用車）
 * @param divide_type_id 振分分類ID
 * @param sea_route_code 航路コード
 * @param start_date 開始日
 * @param end_date 終了日
 * @param car_count 乗用車台数
 * @returns 在庫情報
 */
export async function getStockCar(
    divide_type_id: number,
    sea_route_code: string,
    start_date: string,
    end_date: string,
    car_count: number,
): Promise<StockDetail> {
    const stock_detail = {} as StockDetail
    const postData = {
        divide_type_id: divide_type_id,
        sea_route_code: sea_route_code,
        start_date: start_date,
        end_date: end_date,
        car_count: car_count,
    }
    await apiClient
        .post(`${API_ORDER}/stock/car`, postData)
        .then((res: AxiosResponse<any>) => {
            const { status, data } = res
            stock_detail.ship_schedule_id = data[0].ship_schedule_id
            stock_detail.dep_date = data[0].dep_date
            stock_detail.empty_status = data[0].empty_count
        })
        .catch((e: AxiosError<{ error: string }>) => {
            // エラー処理
            console.log(e.message)
        })

    return stock_detail
}
/**
 * 在庫の確認（二輪車）
 * @param divide_type_id 振分分類ID
 * @param sea_route_code 航路コード
 * @param start_date 開始日
 * @param end_date 終了日
 * @param bicycle_count 二輪車台数
 * @returns 在庫情報
 */
export async function getStockBicycle(
    divide_type_id: number,
    sea_route_code: string,
    start_date: string,
    end_date: string,
    bicycle_count: number,
): Promise<StockDetail> {
    const stock_detail = {} as StockDetail
    const postData = {
        divide_type_id: divide_type_id,
        sea_route_code: sea_route_code,
        start_date: start_date,
        end_date: end_date,
        bicycle_count: bicycle_count,
    }
    await apiClient
        .post(`${API_ORDER}/stock/bicycle`, postData)
        .then((res: AxiosResponse<any>) => {
            const { status, data } = res
            stock_detail.ship_schedule_id = data[0].ship_schedule_id
            stock_detail.dep_date = data[0].dep_date
            stock_detail.empty_status = data[0].empty_count
        })
        .catch((e: AxiosError<{ error: string }>) => {
            // エラー処理
            console.log(e.message)
        })

    return stock_detail
}
/**
 * 在庫の確認（ペットルーム）
 * @param sea_route_code 航路コード
 * @param dep_date 乗船日
 * @param large_count ケージ大利用数
 * @param middle_count ケージ中小利用数
 * @returns 在庫情報
 */
export async function getStockPets(sea_route_code: string, dep_date: string, large_count: number, middle_count: number): Promise<StockDetail> {
    const stock_detail = {} as StockDetail
    const postData = {
        sea_route_code: sea_route_code,
        dep_date: dep_date,
        large_count: large_count,
        middle_count: middle_count,
    }
    await apiClient
        .post(`${API_ORDER}/stock/pets`, postData)
        .then((res: AxiosResponse<any>) => {
            const { status, data } = res
            stock_detail.ship_schedule_id = data[0].ship_schedule_id
            stock_detail.large_status = data[0].large_empty_status
            stock_detail.middle_status = data[0].middle_empty_status
        })
        .catch((e: AxiosError<{ error: string }>) => {
            // エラー処理
            console.log(e.message)
        })

    return stock_detail
}
// 乗船者運賃
export function getPassengerPrice(passenger: PassengerDetail | null | undefined): number {
    let price = 0

    if (isNull(passenger)) {
        return price
    }

    // 運賃単価(BAF抜き金額)
    if (!isNull(passenger!.unit_price)) {
        price += passenger!.unit_price!
    }
    // BAF料金
    if (!isNull(passenger!.baf_amount)) {
        price += passenger!.baf_amount!
    }
    // 貸切料単価
    if (passenger!.reserved_room && !isNull(passenger!.reserved_amount)) {
        price += passenger!.reserved_amount!
    }
    // withペット料金単価
    if (passenger!.with_pets_flg && !isNull(passenger!.with_pets_amount)) {
        price += passenger!.with_pets_amount!
    }

    return price
}
// 車両運賃
export function getCarPrice(car: CarDetail): number {
    let price = 0

    // 運賃単価(BAF抜き金額)
    if (!isNull(car.unit_price)) {
        price += car.unit_price!
    }
    // BAF料金
    if (!isNull(car.baf_amount)) {
        price += car.baf_amount!
    }
    // 積代行料単価
    if (!isNull(car.load_amount)) {
        price += car.load_amount!
    }
    // 降代行料単価
    if (!isNull(car.unload_amount)) {
        price += car.unload_amount!
    }

    return price
}

// ページ間データ設定
export function setRouterPushProperties({
    isUpdate = true,
    base_data = null as RouterPushProperties | null,
    empty_count = null as number | null,
    move_after_login = null as string | null,
}) {
    let tmpData = {} as RouterPushProperties
    if (isUpdate) {
        tmpData = getRouterPushPropertiesData()
    } else if (isNull(base_data)) {
        tmpData = base_data!
    }
    if (isNull(tmpData)) {
        tmpData = {} as RouterPushProperties
    }
    if (!isNull(empty_count)) {
        tmpData.empty_count = Number(empty_count)
    }
    if (!isNull(move_after_login)) {
        tmpData.move_after_login = move_after_login
    }

    setRouterPushPropertiesData(tmpData)
}
export function getRouterPushProperties(): RouterPushProperties {
    return getRouterPushPropertiesData()
}
